import { useContext } from "react";
import { MyContext } from "../../context";

import ContactUs from "../ContactUs";

const AllProperties = () => {
  const context = useContext(MyContext);
  const handleAllPropertiesMapFunction = context.handleAllPropertiesMapFunction;

  return (
    <>
      <div className="container" id="viewAll">
        <div className="text-filler">
          <p className="text-filler-title">Properties from SMDC</p>
          {/* <p className='text-filler-info'></p> */}
        </div>
      </div>
      <div className="container">
        <div className="card-container">{handleAllPropertiesMapFunction()}</div>
      </div>
      <ContactUs />
    </>
  );
};

export default AllProperties;
