import { useContext, useEffect } from "react";
import { MyContext } from "../../context";

const Properties = () => {

    useEffect(() => {
        document.title = 'Luxury Living at its Finest: Explore SMDC Properties Premium Features';
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", "Discover the epitome of luxury and sophistication with SMDC's premier properties.From exquisite design and high - end amenities to prime locations and enhanced security, our premiere developments offer indulgence, exclusivity, and unparalleled living experiences.");

        // let docuURL = document.URL;
        // let linkCanon = document.querySelector("link[rel='canonical']");
        // linkCanon.setAttribute("href", docuURL);
    });


    const context = useContext(MyContext);
    const handlePropertiesButton = context.handlePropertiesButton;
    const handleShowProperties = context.handleShowProperties;

    return (
        <>
            <div className='properties-banner'>
                <div className='page-banner-content'>
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <h1>SMDC Properties</h1>
                        <p>While every SMDC property has its own identity,
                            each one has premium features such as grand lobbies, premium amenities,
                            and excellent property management.</p>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='properties-button-container'>

                    <div className='properties-div'>
                        <h2>Premier</h2>
                        <div className='properties-div-details'>
                            <p>In summary, SMDC's premiere properties represent the epitome of luxury and sophistication. With exquisite design, high-end amenities, prime locations, enhanced security, and premium services, these developments offer a lifestyle of indulgence and exclusivity. For individuals who aspire to live in the lap of luxury, SMDC's premiere properties provide an exceptional choice that embodies opulence, refinement, and unparalleled living experiences.</p>
                            <div className='properties-div-buttons-container'>
                                <a href='#premier'><button
                                    onClick={handlePropertiesButton}
                                    name='premier'
                                >Show Properties</button></a>
                            </div>
                        </div>
                    </div>

                    <div className='properties-div'>
                        <h2>Best Seller</h2>
                        <div className='properties-div-details'>
                            <p>SMDC has consistently delivered exceptional properties that resonate with buyers seeking quality, convenience, and a fulfilling lifestyle. Shore Residences, Sands Residences, Calm Residences, and Sail Residences exemplify the best-selling properties within the SMDC portfolio. Each development offers unique features, modern amenities, and strategic locations that cater to the diverse needs and preferences of buyers. Whether it's a coastal haven, tranquil suburban living, convenient city access, or urban connectivity, SMDC's best-selling properties provide exceptional choices for discerning buyers looking to invest in a home that meets their aspirations and enhances their quality of life.</p>
                            <div className='properties-div-buttons-container'>
                                <a href='#bestSeller'><button
                                    onClick={handlePropertiesButton}
                                    name='bestSeller'
                                >Show Properties</button></a>
                            </div>
                        </div>
                    </div>

                    <div className='properties-div'>
                        <h2>Pre-Selling</h2>
                        <div className='properties-div-details'>
                            <p>SMDC preselling properties are a smart investment choice due to their competitive pricing, flexible payment terms, capital appreciation potential, prime locations, and high-quality development. Thorough research and consultation are recommended to make an informed investment decision.</p>
                            <div className='properties-div-buttons-container'>
                                <a href='#preSelling'><button
                                    onClick={handlePropertiesButton}
                                    name='preSelling'
                                >Show Properties</button></a>
                            </div>
                        </div>
                    </div>

                    <div className='properties-div'>
                        <h2>Ready for Occupancy</h2>
                        <div className='properties-div-details'>
                            <p>Choosing an SMDC RFO project offers peace of mind to homeowners. Since the property is already built and ready for occupancy, there is no uncertainty about construction delays or unforeseen issues that may arise during the building process. Homeowners can enjoy their new space with the assurance that it meets their expectations and that they have immediate control over their investment.</p>
                            <div className='properties-div-buttons-container'>
                                <a href='#rfo'><button
                                    onClick={handlePropertiesButton}
                                    name='rfo'
                                >Show Properties</button></a>
                            </div>
                        </div>
                    </div>

                    <div className='properties-div'>
                        <h2>SMDC Properties</h2>
                        {/* <picture>
                            <source type='image/avif' srcSet=''/>
                            <source type='image/webp' srcSet='' alt='' />
                            <img src='' alt='' />
                        </picture> */}
                        <div className='properties-div-details'>
                            <p>View all SMDC Properties. While every SMDC property has its own identity, each one has premium features such as grand lobbies, premium amenities, and excellent property management.</p>
                            <div className='properties-div-buttons-container'>
                                <a href='#viewAll'><button
                                    onClick={handlePropertiesButton}
                                    name='viewAll'
                                >All Properties</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {handleShowProperties()}
        </>
    )
}

export default Properties;