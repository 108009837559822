import { useContext, useEffect } from "react";
import { MyContext } from "../../context";
import { Link } from "react-router-dom";

import ContactUS from "../ContactUs";
import Articles from "./Articles";
// import ContactIcons from "../ContactIcons";

const Home = () => {
  useEffect(() => {
    document.title =
      "Discover Exquisite Condo Living at SMDC | Elevate Your Lifestyle";
    let descMeta = document.querySelector("meta[name='description']");
    descMeta.setAttribute(
      "content",
      "Experience the epitome of luxury living with SMDC's premium residential condominiums.Elevate your lifestyle with our exquisite condo developments, offering choicest amenities and features.Find your dream home today and invest in the future of your living experience."
    );
    // let themeMeta = document.querySelector("meta[name='theme-color']");
    // themeMeta.setAttribute("content", "#ffffff");
  });

  const context = useContext(MyContext);
  const handleBestSellersMapFunction = context.handleBestSellersMapFunction;
  const carouselGetData = context.carouselGetData;

  return (
    <>
      {carouselGetData()}
      <div className="container">
        <div className="text-filler">
          <h1 className="text-filler-title">
            Elevate Your Living Experience with SMDC
          </h1>
          <p className="text-filler-info">
            We have the makings of your next dream home. Find the best home for
            your lifestyle among our selection of premium residential
            condominiums.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="card-container">{handleBestSellersMapFunction()}</div>
      </div>
      <div className="container">
        <div
          className="button-view-more-properties"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Link to="/Properties">
            <button>VIEW MORE PROPERTIES</button>
          </Link>
        </div>
      </div>
      <Articles />
      <ContactUS />
      {/* <ContactIcons /> */}
    </>
  );
};

export default Home;
