import React, { useEffect } from "react";
import { MyProvider } from "../context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "./Home/Home";
import ContactUs from "./ContactUs";
import Search from "./Search";
import Properties from "./Home/Properties";
// import PreSellingSingleItem from "../components/Pre-Selling/PreSellingSingleItem";
import ShowBestSellerSingleItem from "../components/Best-Sellers/BestSellerShowSingleItem";
// import ShowRFO from "./Ready for Occupancy/ShowRFO";
import PreSellingShowSIngleItem from "./Pre-Selling/PreSellingShowSIngleItem";
import ShowRFOSingleItem from "./Ready for Occupancy/ShowRFOSingleItem";
import PremierShowSingleItem from "./Premier/PremierShowSingleItem";
import AllPropertiesShowSingleItem from "./All Properties/AllPropertiesShowSingleItem";
import HowToReserve from "../components/HowToReserve";
import SingleItemDynamic from "./singleItemDynamic";
import NoRouteFound from "./NoRouteFound";
import ContactIcons from "./ContactIcons";
import ContactFormModal from "./ContactFormModal";
const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <BrowserRouter>
      <MyProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/properties/:name" element={<SingleItemDynamic />} />
          <Route path="/search" element={<Search />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/reservations" element={<HowToReserve />} />
          {/* <Route path='/PreSellingItemList' element={<PreSellingSingleItem />} /> */}
          <Route path="/pre-selling" element={<PreSellingShowSIngleItem />} />
          <Route
            path="/pre-selling/:name"
            element={<PreSellingShowSIngleItem />}
          />
          <Route path="/best-seller" element={<ShowBestSellerSingleItem />} />
          <Route
            path="/best-seller/:name"
            element={<ShowBestSellerSingleItem />}
          />
          {/* <Route path='/ShowRFO' element={<ShowRFO />} /> */}
          <Route path="/ready-for-occupancy" element={<ShowRFOSingleItem />} />
          <Route
            path="/ready-for-occupancy/:name"
            element={<ShowRFOSingleItem />}
          />
          <Route
            path="/premier-properties"
            element={<PremierShowSingleItem />}
          />
          <Route
            path="/premier-properties/:name"
            element={<PremierShowSingleItem />}
          />
          <Route
            path="/SMDC-Properties"
            element={<AllPropertiesShowSingleItem />}
          />
          <Route path="*" element={<NoRouteFound />} />
          {/* <Route path='/properties/*' element={<SingleItemDynamic />} /> */}
        </Routes>
        <Footer />
        <ContactIcons />
        <ContactFormModal />
      </MyProvider>
    </BrowserRouter>
  );
};

export default App;
