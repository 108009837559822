import { useEffect } from 'react';
import SMDCLogo from './smdcLogo.png';

const HowtoReserve = () => {

    useEffect(() => {
        document.title = 'SMDC Condominium Reservations'
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Ready to reserve your dream SMDC condominium? Follow these simple steps to secure your unit and start living your best life!');
    });

    return (
        <>
            <div className="how-to-reserve">
                <div className='text-filler' data-aos="fade-up" data-aos-duration="1000">
                    <h1 className='text-filler-title-howToReserve'>Condo Reservation</h1>
                </div>
            </div>
            <div className='container'>
                <div className='how-to-reserve-info' data-aos="fade-up" data-aos-duration="2000">
                    <h2>For locally based clients</h2>
                    <p><b>1.</b> Contact us directly, for unit availability and showroom viewing.</p>
                    <p><b>2.</b> Choose your unit floor level, preferred unit number. Choose atleast 3 units for availability options. Ask for a project computation or payment options.</p>
                    <p><b>3.</b> Reservation of Unit (we accept Checks, Credit Cards and Cash).</p>
                    <p><b>4.</b> Submit requirements (2 Government issued ID's with signature).</p>
                </div>
                <div className='how-to-reserve-info' data-aos="fade-up" data-aos-duration="2000">
                    <h2>For our Clients Abroad</h2>
                    <p><b>1.</b> Contact us directly, for unit availability and showroom viewing.</p>
                    <p><b>2.</b> Reservation Forms will be sent to your email, kindly fill up the forms and return to us the scanned copy.</p>
                    <p><b>3.</b> Email us the scanned copy of your 2 valid government issued ID's with signature.</p>
                    <p><b>4.</b> Payment Methods:</p>
                    <ul>
                        <li>BDO Online Bills Paymen</li>
                        <li>Wire Transfer (swift code)</li>
                        <li>Kindly keep the PROOF OF PAYMENT and send us a copy of the receipt for reference.</li>
                        <li>You may call us at your most convenient time for inquiries and assistance.</li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='how-to-reserve-info-img'>
                    <img src={SMDCLogo} alt='SMDC Logo' />
                    <p className='container' data-aos="fade-up" data-aos-duration="1000">
                        SM Development Corporation commits itself to provide access to luxurious urban living through its vertical villages and gated horizontal communities, designed with thoughtful features and generous resort-like amenities, all perfectly integrated with a commercial retail environment, thus giving its residents access to a truly cosmopolitan lifestyle.
                    </p>
                </div>
            </div>
        </>
    )
}

export default HowtoReserve;