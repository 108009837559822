import { useContext } from "react";
import { MyContext } from "../../context";
import { Link } from "react-router-dom";

const PreSellingItemList = (props) => {
  const context = useContext(MyContext);
  const handleShowDivIndex = context.handleShowDivIndex;
  const item = props.items;
  const divIndex = props.index;

  const cardBackground = {
    backgroundImage: `url(${item.cardImage})`,
    backgroundSize: "cover",
    width: "100%",
    // height: '23rem',
    position: "relative",
    key: "",
    alt: "",
  };

  const itemList = () => {
    return (
      <Link className="text-decoration-none" to="/pre-selling">
        <div
          key={item.id}
          className="card"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-index={divIndex}
          onClick={handleShowDivIndex}
        >
          <div className="card-image-container" style={{ ...cardBackground }}>
            <div>
              <img src={item.logo.white} alt="White Logo" />
            </div>
            <p className="card-status">{item.status}</p>
          </div>
          <div className="card-info">
            <h2>{item.name}</h2>
            <div>
              <p className="font-blue font-size-15rem margin-zero margin-top-zero">
                {item.cardPrice}
              </p>
              <p className="font-blue font-size-15rem margin-zero">
                {item.cardDiscount}
              </p>
            </div>
            <p>{item.cardDescription}</p>
            <p>
              <span>
                <i className="fa-solid fa-location-dot"></i>
              </span>{" "}
              {item.cardAddress}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  return <>{itemList()}</>;
};

export default PreSellingItemList;
