import { useContext } from "react";
import { Link } from "react-router-dom";
import { MyContext } from "../../context";

const BestSellers = (props) => {
  const data = props.items;
  const index = props.index;
  const context = useContext(MyContext);
  const handleShowDivIndex = context.handleShowDivIndex;

  const cardBackground = {
    backgroundImage: `url(${data.cardImage})`,
    backgroundSize: "cover",
    width: "100%",
    // height: "23rem",
    position: "relative",
    key: "",
    alt: "",
  };

  const propertiesPreview = () => (
    <Link
      className="text-decoration-none"
      // to={`/best-seller/${data.name.split(" ").join("-")}`}
      to="/best-seller"
      key={data.id}
    >
      <div
        className="card"
        data-aos="fade-up"
        data-aos-duration="1000"
        key={data.id}
        data-index={index}
        onClick={handleShowDivIndex}
      >
        <div className="card-image-container" style={{ ...cardBackground }}>
          <div>
            <img src={data.logo.white} alt="White Logo" />
          </div>
          <p className="card-status">{data.status}</p>
        </div>
        <div className="card-info">
          <h2>{data.name}</h2>
          <div>
            <p className="font-blue font-size-15rem margin-zero margin-top-zero">
              {data.cardPrice}
            </p>
            <p className="font-blue font-size-15rem margin-zero">
              {data.cardDiscount}
            </p>
          </div>
          <p>{data.cardDescription}</p>
          <p>
            <span>
              <i className="fa-solid fa-location-dot"></i>
            </span>
            {data.cardAddress}
          </p>
        </div>
      </div>
    </Link>
  );

  return <>{propertiesPreview()}</>;
};

export default BestSellers;
