import { useContext, useEffect } from "react";
import { MyContext } from "../context";

const Search = () => {

    useEffect(() => {
        document.title = 'SMDC Search | Looking for your dream home?';
        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Looking for your dream home? Search for SMDC properties with ease using our user-friendly search tool. Find the perfect property for you today!');
    });

    const context = useContext(MyContext)
    const searchInput = context.searchInput;
    const searchItem = context.searchItem;
    return (
        <>
            <div className='search-container'>
                <div className='search'>
                    <div className='container' data-aos="fade-up" data-aos-duration="1000">
                        <h1>Search SMDC Properties</h1>
                        <input type='text' onChange={searchInput} placeholder='Fame Residences' />
                        <p>
                            Find your dream property with our search tool.
                            Simply enter the name of the property you want to check,
                            and see the results.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                {searchItem()}
            </div>
        </>
    )
}

export default Search;