const SearchItem = ({ items }) => {

    const cardBackground = {
        backgroundImage: `url(${items.cardImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '23rem',
        position: 'relative',
        key: '',
        alt: ''
    };

    const bannerFeatures = items.bannerFeatures.map((items, index) => (
        <p key={index}>{items}</p>
    ));

    const bannerAmenities = items.bannerAmenities.map((items, index) => (
        <p key={index}>{items}</p>
    ));

    const gallery = items.gallery.map((items, index) => (
        <img src={items} alt='gallery-img' key={index} />
    ))

    return (
        <div className='container margin-top-5rem'>
            <div
                key={items.id}
                className='search-item-card'
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <div className='search-item-image-container' style={{ ...cardBackground }}>
                    <div>
                        <img src={items.logo.white} alt='White Logo' />
                    </div>
                    <p className='search-item-card-status'>{items.status}</p>
                </div>
                <div className='search-item-card-info'>
                    <h2>{items.name}</h2>
                    <div>
                        <p className='font-blue font-size-15rem margin-zero'>{items.cardDiscount}</p>
                        <p className='font-blue font-size-15rem margin-zero margin-top-zero'>{items.cardPrice}</p>
                    </div>
                    <h2>Description</h2>
                    <p>{items.cardDescription}</p>
                    <h2>Address</h2>
                    <p>{items.cardAddress}</p>
                </div>
                <div className='search-details'>
                    <div>
                        <h2>Features</h2>
                        {bannerFeatures}
                    </div>
                    <div>
                        <h2>Amenities</h2>
                        {bannerAmenities}
                    </div>
                </div>
            </div>
            <div className='search-logo-black'>
                <img src={items.logo.black} alt={items.name} />
            </div>
            <div className='search-image-gallery search-details'>
                {gallery}
            </div>
        </div>
    )
}

export default SearchItem;