import { useContext } from "react";
import { MyContext } from "../../context";


const Articles = () => {

    const context = useContext(MyContext);
    const handleArticleButton = context.handleArticleButton;
    const articleButton = context.state.articleButton;
    const homeArticlesJSON = context.state.homeArticlesJSON;

    const articles = () => {

        const active = 'article-heading-active';
        const notActive = 'article-heading-notActive';
        const show = 'summary-show';
        const hide = 'summary-hidden';

        return homeArticlesJSON.map((item) => ((
            <>
                <button id={item.id} onClick={handleArticleButton} className={(articleButton === parseInt(item.id) ? active : notActive)}>{item.heading}</button>
                <div onClick={handleArticleButton} className={(articleButton === parseInt(item.id) ? show : hide)}>
                    <div className='article-container'>
                        {item.paragraph.map((item) => (
                            <p className='article-info'>{item}</p>
                        ))}
                    </div>
                </div>
            </>
        )))
    }

    return (
        <div className='container'>
            {articles()}
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default Articles;