import React, { Component } from "react";
import homeArticlesJSON from "../homeArticles.json";
import PreSellingJSON from "../preSelling.json";
import bestSellersJSON from "../bestSellers.json";
import PreSellingItemList from "../components/Pre-Selling/preSellingItemList";
import RFOJSON from "../RFO.json";
import PropertiesJSON from "../Properties.json";
import PremierJSON from "../Premier.json";
import PremierItemList from "../components/Premier/PremierItemList";
import PreSelling from "../components/Pre-Selling/PreSelling";
import Banner from "../components/Home/Banner";
import BestSellers from "../components/Best-Sellers/BestSellers";
import BestSellersItemList from "../components/Best-Sellers/BestSellersItemList";
import RFO from "../components/Ready for Occupancy/RFO";
import SearchItem from "../components/SearchItem";
import ShowRFO from "../components/Ready for Occupancy/ShowRFO";
import Premier from "../components/Premier/Premier";
import AllProperties from "../components/All Properties/AllProperties";
import AllPropertiesItemList from "../components/All Properties/AllPropertiesItemList";
import Sitemap from "../components/sitemap";
const MyContext = React.createContext();

class MyProvider extends Component {
  state = {
    preSellingJSON: PreSellingJSON,
    preSellingIndex: "",
    bestSellersJSON: bestSellersJSON,
    bestSellersIndex: "",
    RFOJSON: RFOJSON,
    RFOIndex: "",
    PropertiesJSON: PropertiesJSON,
    PropertiesIndex: "",
    PremierJSON: PremierJSON,
    PremierIndex: "",
    homeArticlesJSON: homeArticlesJSON,
    divIndex: 0,
    carouselImageData: [],
    carouselGetData: 3,
    carouselIndexData: 3,
    newDivIndex: [],
    ShowProperties: "",
    emailStatus: "",
    filtered: [],
    searchInput: "fame",
    showImages: false,
    propertiesButton: "",
    burgerIconExpand: false,
    isNavExpanded: false,
    articleButton: 1,
    propertyShowSingleItem: "",
    contactFormModalActive: false,
  };

  handleContactFormModal = () => {
    this.setState({
      contactFormModalActive: !this.state.contactFormModalActive,
    });
  };

  handleShowSiteMap = () => {
    let preSellingArr = [];
    this.state.preSellingJSON.map((preSelling) =>
      preSellingArr.push(preSelling)
    );

    let premierArr = [];
    this.state.PremierJSON.map((premier) => premierArr.push(premier));

    let bestSellerArr = [];
    this.state.bestSellersJSON.map((bestSeller) =>
      bestSellerArr.push(bestSeller)
    );

    let RFOArr = [];
    this.state.RFOJSON.map((rfo) => RFOArr.push(rfo));

    let allPropertiesArr = [];
    this.state.PropertiesJSON.map((properties) =>
      allPropertiesArr.push(properties)
    );

    return (
      <Sitemap
        preSellingArr={preSellingArr}
        premierArr={premierArr}
        bestSellerArr={bestSellerArr}
        RFOArr={RFOArr}
        allPropertiesArr={allPropertiesArr}
      />
    );
  };

  handlePropertyShowSingleItem = (e) => {
    console.log(e);
    this.setState({ propertyShowSingleItem: e.target.name });
  };

  handleArticleButton = (e) => {
    const articleButton = this.state.articleButton;
    this.setState({
      articleButton:
        articleButton === parseInt(e.target.id) ? 0 : parseInt(e.target.id),
    });
  };

  handleCarouselImages = () => {
    const carouselImageData = this.state.carouselImageData;
    const PremierJSON = this.state.PremierJSON;
    if (carouselImageData === []) {
      PremierJSON.map((items) => carouselImageData.push(items));
    }
    return <Banner items={carouselImageData} />;
  };

  handleBurgerIconExpand = () => {
    const isNavExpanded = this.state.isNavExpanded;
    this.setState({ isNavExpanded: !isNavExpanded });
  };

  handleIsNavExpanded = () => {
    const isNavExpanded = this.state.isNavExpanded;
    return isNavExpanded ? "navigation-menu expanded" : "navigation-menu";
  };

  handleCloseBurgerIcon = () => {
    this.setState({ isNavExpanded: false });
  };

  handlePropertiesButton = (e) => {
    this.setState({ propertiesButton: e.target.name });
  };

  handleShowProperties = () => {
    const propertiesButton = this.state.propertiesButton;
    if (propertiesButton === "premier") {
      return <Premier />;
    }
    if (propertiesButton === "bestSeller") {
      return <BestSellers />;
    }
    if (propertiesButton === "preSelling") {
      return <PreSelling />;
    }
    if (propertiesButton === "rfo") {
      return <ShowRFO />;
    }
    if (propertiesButton === "viewAll") {
      return <AllProperties />;
    }
  };

  handleShowImagesButton = () => {
    this.setState({ showImages: true });
  };

  searchInput = (e) => {
    this.setState({ searchInput: e.target.value });
    let keywords = e.target.value.toLowerCase();
    let filtered = this.state.PropertiesJSON.filter((item) => {
      return item.name.toLowerCase().indexOf(keywords) > -1;
    });
    if (keywords.length > 1) {
      return this.setState({ filtered: filtered });
    }
    if (keywords === "") {
      return this.setState({ filtered: [] });
    }
  };

  searchItem = () => {
    return this.state.filtered.map((items) => <SearchItem items={items} />);
  };

  carouselGetData = () => {
    const PremierJSON = this.state.PremierJSON;
    return <Banner data={PremierJSON} />;
  };

  handleAllPropertiesMapFunction = () => {
    return this.state.PropertiesJSON.map((items, index) => (
      <AllPropertiesItemList items={items} index={index} />
    ));
  };

  handlePremierMapFunction = () => {
    return this.state.PremierJSON.map((items, index) => (
      <PremierItemList items={items} index={index} />
    ));
  };

  handleRFOMapFunction = () => {
    return this.state.RFOJSON.map((items, index) => (
      <RFO items={items} index={index} />
    ));
  };

  handleBestSellersMapFunction = () => {
    return this.state.bestSellersJSON.map((items, index) => (
      <BestSellersItemList items={items} index={index} />
    ));
  };

  handlePreSellingMapFunction = () => {
    return this.state.preSellingJSON.map((items, index) => (
      <PreSellingItemList items={items} index={index} />
    ));
  };

  handleShowDivIndex = (e) => {
    this.setState({ divIndex: parseInt(e.currentTarget.dataset.index) });
  };

  render() {
    console.log(this.state.propertyShowSingleItem);

    const {
      handlePreSellingMapFunction,
      handleShowDivIndex,
      handleBestSellersMapFunction,
      handleRFOMapFunction,
      searchInput,
      searchItem,
      handleShowImagesButton,
      handlePropertiesButton,
      handleShowProperties,
      handlePremierMapFunction,
      handleAllPropertiesMapFunction,
      handleCloseBurgerIcon,
      handleBurgerIconExpand,
      handleIsNavExpanded,
      handleCarouselImages,
      carouselGetData,
      handleArticleButton,
      handlePropertyShowSingleItem,
      handleShowSiteMap,
      handleContactFormModal,
      state,
    } = this;

    return (
      <MyContext.Provider
        value={{
          handlePreSellingMapFunction,
          handleShowDivIndex,
          handleBestSellersMapFunction,
          handleRFOMapFunction,
          searchInput,
          searchItem,
          handleShowImagesButton,
          handlePropertiesButton,
          handleShowProperties,
          handlePremierMapFunction,
          handleAllPropertiesMapFunction,
          handleCloseBurgerIcon,
          handleBurgerIconExpand,
          handleIsNavExpanded,
          handleCarouselImages,
          carouselGetData,
          handleArticleButton,
          handlePropertyShowSingleItem,
          handleShowSiteMap,
          handleContactFormModal,
          state,
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export { MyContext, MyProvider };
