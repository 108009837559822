import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  useEffect(() => {
    document.title = "SMDC Contact Us | Get in touch with our agents now.";
    let descMeta = document.querySelector("meta[name='description']");
    descMeta.setAttribute(
      "content",
      "Need to get in touch with SMDC? Contact us through email, Facebook chat, or other channels. We are here to help with any questions or concerns you may have."
    );
  });

  const form = useRef();
  const [emailStatus, setEmailStatus] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tfeid5o",
        "template_91pw5q7",
        form.current,
        "e04CtcKkAAwLfi5Wv"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailStatus("Email sent successfuly!");
        },
        (error) => {
          console.log(error.text);
          setEmailStatus("Sorry, try again later.");
        }
      );
  };

  const sendStatus = emailStatus ? (
    <label className="success">{emailStatus}</label>
  ) : (
    <label className="failed">{emailStatus}</label>
  );

  return (
    <>
      <div className="contact-banner">
        <div className="page-banner-content">
          <div data-aos="fade-up" data-aos-duration="1000">
            <h1>SMDC is here for you</h1>
            <p>Email - Messenger Chat - Direct Call - Viber - WhatsApp</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-filler" id="contact-form">
          <p className="text-filler-title">Contact Us</p>
          <p className="text-filler-info font-lato">
            If you are considering SMDC or just want more information, we're
            happy to answer all your questions. Simply fill out the form below
            and we will reach out soon.
          </p>
        </div>
        <div className="email-container">
          <div
            className="contact-and-input-container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="contact">
              <div className="contact-title">
                <p className="font-lato font-size-15rem">
                  Contact Informations
                </p>
              </div>
              <div className="contact-div">
                <div className="contact-info">
                  <i className="fa-regular fa-envelope contact-icon"></i>
                  <p>Email:</p>
                  {/* <p>rai17.smdc@gmail.com</p> */}
                  <p>geraldinegarcia.smdc</p>
                </div>
                <div className="contact-info">
                  <i className="fa-solid fa-mobile-screen contact-icon"></i>
                  <p>Mobile No:</p>
                  <p>+639457230927</p>
                </div>
                <div className="contact-info">
                  <i className="fa-brands fa-viber contact-icon"></i>
                  <p>Viber:</p>
                  <p>+639457230927</p>
                </div>
                <div className="contact-info">
                  <i className="fa-brands fa-whatsapp contact-icon"></i>
                  <p>WhatsApp:</p>
                  <p>+639457230927</p>
                </div>
                <div className="contact-info">
                  <i className="fa-brands fa-facebook-f contact-icon"></i>
                  <p>Facebook:</p>
                  <p>
                    <a
                      // href="https://www.facebook.com/smresidencesbygeraldinegarcia"
                      href="https://www.facebook.com/profile.php?id=100063985661711"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none font-lato font-grey"
                    >
                      SMDC Properties
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="input">
              <div className="input-container">
                <form ref={form} onSubmit={sendEmail}>
                  <div>
                    <input
                      type="email"
                      name="rai17.smdc@gmail.com"
                      placeholder="To:   SMDC Properties"
                      readOnly
                    />
                  </div>
                  <div>
                    <input type="text" name="user_name" placeholder="Name" />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="mobile_number"
                      placeholder="Mobile No."
                    />
                  </div>
                  {/* <div>
                                    <label>Please include your "Email" and "Contact Details" in the message.</label>
                                </div> */}
                  <div>
                    <textarea
                      name="message"
                      placeholder="Please include your Email Address and Contact Details in the message."
                    />
                  </div>
                  <div>{sendStatus}</div>
                  <div>
                    <button
                      className="button-disabled"
                      type="submit"
                      value="Send"
                      disabled
                    >
                      SEND MESSAGE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
