import { useContext, useEffect } from "react";
import { MyContext } from "../../context";
import { Link } from "react-router-dom";

const RFO = (props) => {
  const context = useContext(MyContext);
  const handleShowDivIndex = context.handleShowDivIndex;
  const handlePropertyShowSingleItem = context.handlePropertyShowSingleItem;
  const items = props.items;
  const index = props.index;

  const cardBackground = {
    backgroundImage: `url(${items.cardImage})`,
    backgroundSize: "cover",
    width: "100%",
    // height: '23rem',
    position: "relative",
    key: `${items.id}`,
    alt: `${items.name}`,
  };

  const itemName = items.name.toLowerCase().split(" ")[0];
  // const url = `/ready-for-occupancy/${items.name}`
  console.log(itemName);

  useEffect(() => {
    document.title = `SMDC Ready for Occupancy`;
  });

  return (
    <>
      <Link
        className="text-decoration-none"
        to="/ready-for-occupancy"
        onClick={handlePropertyShowSingleItem}
        name={itemName}
        key={items.id}
      >
        <div
          className="card"
          data-aos="fade-up"
          data-aos-duration="1000"
          key={items.id}
          data-index={index}
          onClick={handleShowDivIndex}
          dataset={itemName}
        >
          <div className="card-image-container" style={{ ...cardBackground }}>
            <div>
              <img src={items.logo.white} alt="White Logo" />
            </div>
            <p className="card-status">{items.status}</p>
          </div>
          <div className="card-info">
            <h2>{items.name}</h2>
            <div>
              <p className="font-blue font-size-15rem margin-zero margin-top-zero">
                {items.cardPrice}
              </p>
              <p className="font-blue font-size-15rem margin-zero">
                {items.cardDiscount}
              </p>
            </div>
            <p>{items.cardDescription}</p>
            <p>
              <span>
                <i className="fa-solid fa-location-dot"></i>
              </span>
              {items.cardAddress}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default RFO;
