import { Link } from "react-router-dom";

const Sitemap = (props) => {

    const premierArr = () => {
        return props.premierArr.map(item => {
            let newURL = `https://www.sm-residences.com/properties/${item.name.toLowerCase().split(' ').join('-')}`;
            return <p><Link to={newURL}>{item.name}</Link></p>
        });
    };

    const bestSellerArr = () => {
        return props.bestSellerArr.map(item => {
            let newURL = `https://www.sm-residences.com/properties/${item.name.toLowerCase().split(' ').join('-')}`;
            return <p><Link to={newURL}>{item.name}</Link></p>
        });
    };

    const preSellingArr = () => {
        return props.preSellingArr.map(item => {
            let newURL = `https://www.sm-residences.com/properties/${item.name.toLowerCase().split(' ').join('-')}`;
            return <p><Link to={newURL}>{item.name}</Link></p>
        });
    };

    const RFOArr = () => {
        return props.RFOArr.map(item => {
            let newURL = `https://www.sm-residences.com/properties/${item.name.toLowerCase().split(' ').join('-')}`;
            return <p><Link to={newURL}>{item.name}</Link></p>
        });
    };

    const allPropertiesArr = () => {
        return props.allPropertiesArr.map(item => {
            let newURL = `https://www.sm-residences.com/properties/${item.name.toLowerCase().split(' ').join('-')}`;
            return <p><Link to={newURL}>{item.name}</Link></p>
        });
    };

    return (
        <div className='container'>
            <div className='sitemap'>
                <div className='sitemap-first-div'>
                    <h4>Navigation</h4>
                    <p><Link to='/'>Home</Link></p>
                    <p><Link to='/properties/'>Properties</Link></p>
                    <p><Link to='/reservations'>Reservations</Link></p>
                    <p><Link to='/search'>Search</Link></p>
                    <p><Link to='/contact'>Contact</Link></p>
                </div>
                <div className='sitemap-first-div'>
                    <h4>Social Media</h4>
                    <p><Link to='/'>Facebook</Link></p>
                    <p><Link to='/'>Instagram</Link></p>
                    <p><Link to='/'>Viber</Link></p>
                    <p><Link to='/'>Whatsapp</Link></p>
                    <p><Link to='/'>Telegram</Link></p>
                </div>
                <div className='sitemap-first-div'>
                    <h4>Premier</h4>
                    {premierArr()}
                </div>
                <div className='sitemap-first-div'>
                    <h4>Best Seller</h4>
                    {bestSellerArr()}
                </div>
                <div className='sitemap-first-div'>
                    <h4>R.F.O.</h4>
                    {RFOArr()}
                </div>
                <div className='sitemap-first-div'>
                    <h4>Pre-Selling</h4>
                    {preSellingArr()}
                </div>
                <div className='sitemap-first-div'>
                    <h4>All Properties</h4>
                    {allPropertiesArr()}
                </div>
            </div>
        </div>
    )
}

export default Sitemap;