import { useContext } from "react";
import { MyContext } from "../../context";

const BestSellerSingleItem = () => {
  const context = useContext(MyContext);
  const bestSellersJSON = context.state.bestSellersJSON;
  const handleShowImagesButton = context.handleShowImagesButton;
  const showImages = context.state.showImages;
  const divIndex = context.state.divIndex;
  const singleItem = bestSellersJSON[divIndex];

  const singleItemBackground = {
    backgroundImage: `url(${singleItem.bannerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "90vh",
    width: "100%",
  };

  const bannerFeatures = singleItem.bannerFeatures.map((features, index) => (
    <li className="font-lato" key={index}>
      {features}
    </li>
  ));

  const bannerAmenities = singleItem.bannerAmenities.map((amenities, index) => (
    <li className="font-lato" key={index}>
      {amenities}
    </li>
  ));

  const gallery = singleItem.gallery.map((images, index) => (
    <img
      src={images}
      key={index}
      alt="Gallery"
      data-aos="fade-up"
      data-aos-duration="1000"
    />
  ));

  const showImage = showImages ? gallery : null;

  return (
    <>
      <div
        className="single-item-banner"
        style={{ ...singleItemBackground }}
        alt="Banner"
      >
        <div className="single-item-logo-container">
          <img src={singleItem.logo.white} alt={singleItem.name} />
        </div>
      </div>
      <div>
        <div className="single-item-banner-container">
          <div data-aos="fade-up" data-aos-duration="1000">
            <h1>About</h1>
            <p>{singleItem.bannerAbout}</p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <h1>{singleItem.bannerFeaturesHeading}</h1>
            <ol>{bannerFeatures}</ol>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <h1>{singleItem.bannerAmenitiesHeading}</h1>
            <ol>{bannerAmenities}</ol>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-filler margin-top-5rem">
          <img
            src={singleItem.logo.black}
            alt="logoBlack"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
        </div>
        <div className="button-view-more-properties">
          <button onClick={handleShowImagesButton}>Show Images</button>
        </div>
        <div className="gallery margin-top-5rem">{showImage}</div>
      </div>
    </>
  );
};

export default BestSellerSingleItem;
