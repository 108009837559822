// import { useContext } from "react";
// import { MyContext } from "../context";
import { Link } from "react-router-dom";
const NoRouteFound = () => {

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    // const context = useContext(MyContext);
    // const handleShowSiteMap = context.handleShowSiteMap;

    const invalidURL = document.URL;

    return (
        <>
            <div className='page-not-found container'>
                <p>{invalidURL}</p>
                <h3>Sorry, the URL does not exist</h3>
                <div>
                    <p>Return to <Link to='/'>Home</Link> or <Link to='Search'>Search</Link>  for properties</p>
                </div>
            </div>
            {/* {handleShowSiteMap()} */}
        </>
    )
}

export default NoRouteFound;