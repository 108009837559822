import AllPropertiesSingleItem from "./AllPropertiesSingleItem";
import ContactUs from "../ContactUs";

const AllPropertiesShowSingleItem = () => {
  return (
    <>
      <AllPropertiesSingleItem />
      <ContactUs />
    </>
  );
};

export default AllPropertiesShowSingleItem;
