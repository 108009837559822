import { useContext } from "react";
import { MyContext } from "../../context";

import ContactUs from '../ContactUs';

const Premier = () => {

    const context = useContext(MyContext);
    const handlePremierMapFunction = context.handlePremierMapFunction;

    return (
        <>
            <div className='container' id='premier'>
                <div className='text-filler'>
                    <p className='text-filler-title'>Premier Properties</p>
                    {/* <p className='text-filler-info'>SMDC Preselling condominiums is a once in a lifetime opportunity to own a unit in prime locations while the price is low.</p> */}
                </div>
            </div>
            <div className='container'>
                <div className='card-container'>
                    {handlePremierMapFunction()}
                </div>
            </div>
            <ContactUs />
        </>
    )
}

export default Premier;