import { useContext } from "react";
import { MyContext } from "../../context";

import ContactUs from '../ContactUs';

const BestSellers = () => {

    const context = useContext(MyContext);
    const handleBestSellersMapFunction = context.handleBestSellersMapFunction;

    return (
        <>
            <div className='container' id='bestSeller'>
                <div className='text-filler'>
                    <p className='text-filler-title'>Best-Selling Properties</p>
                    {/* <p className='text-filler-info'>SMDC Preselling condominiums is a once in a lifetime opportunity to own a unit in prime locations while the price is low.</p> */}
                </div>
            </div>
            <div className='container'>
                <div className='card-container'>
                    {handleBestSellersMapFunction()}
                </div>
            </div>
            <ContactUs />
        </>
    )
}

export default BestSellers;