import { useContext, useEffect } from "react";
import { MyContext } from "../context";

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactModal = () => {
  const context = useContext(MyContext);
  const contactFormModalActive = context.state.contactFormModalActive;
  const handleContactFormModal = context.handleContactFormModal;

  const form = useRef();
  const [emailStatus, setEmailStatus] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tfeid5o",
        "template_91pw5q7",
        form.current,
        "e04CtcKkAAwLfi5Wv"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailStatus("Email sent successfuly!");
        },
        (error) => {
          console.log(error.text);
          setEmailStatus("Sorry, try again later.");
        }
      );
  };

  const sendStatus = emailStatus ? (
    <label className="success">{emailStatus}</label>
  ) : (
    <label className="failed">{emailStatus}</label>
  );

  // stops the scroll behaviour of the document body when modal is visible / mounted.
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  // sets up body.style.overlfow to unset when component is closed / unmounted.
  useEffect(() => {
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      {contactFormModalActive ? (
        <div className="contact-form-modal">
          <div className="contact-form-modal-content">
            <div className="input-modal">
              <div className="input-container-modal">
                <i
                  class="fa-solid fa-xmark"
                  onClick={handleContactFormModal}
                ></i>
                <form ref={form} onSubmit={sendEmail}>
                  <div>
                    <input
                      type="email"
                      name="rai17.smdc@gmail.com"
                      placeholder="To:   geraldinegarcia.smdc@gmail.com"
                      readOnly
                    />
                  </div>
                  <div>
                    <input type="text" name="user_name" placeholder="Name" />
                  </div>
                  <div>
                    <input
                      type="number"
                      name="mobile_number"
                      placeholder="Mobile No."
                    />
                  </div>
                  {/* <div>
                                    <label>Please include your "Email" and "Contact Details" in the message.</label>
                                </div> */}
                  <div>
                    <textarea
                      name="message"
                      placeholder="Please include your Email Address and Contact Details in the message."
                    />
                  </div>
                  <div>{sendStatus}</div>
                  <div className="modal-button-container">
                    <div>
                      <button
                        className="button-disabled"
                        type="submit"
                        value="Send"
                        // disabled
                      >
                        SEND MESSAGE
                      </button>
                    </div>
                    <div>
                      <button
                        className="button-disabled"
                        onClick={handleContactFormModal}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ContactModal;
