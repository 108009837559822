import { useContext } from 'react';
import { MyContext } from '../context';
import { Link } from 'react-router-dom';
import SMDCLogo from './smdcLogo.png';

const Navbar = () => {

    const context = useContext(MyContext);
    const handleBurgerIconExpand = context.handleBurgerIconExpand;
    const handleIsNavExpanded = context.handleIsNavExpanded;
    const handleCloseBurgerIcon = context.handleCloseBurgerIcon;

    // window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth',
    // });

    return (
        <nav className="navigation">
            <div className='navbar-logo'>
                <Link className='navbar-links-Link' to='/'><img src={SMDCLogo} alt='smdcLogo' /></Link>
            </div>
            <button className="hamburger" name=''>
                <svg
                    onClick={handleBurgerIconExpand}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div className={handleIsNavExpanded()}>
                <div className='navbar-links'>
                    <ul>
                        <li>
                            <Link className='navbar-links-Link' to='/' onClick={handleCloseBurgerIcon}>Home</Link>
                        </li>
                        <li>
                            <Link className='navbar-links-Link' to='/properties' onClick={handleCloseBurgerIcon}>Properties</Link>
                        </li>
                        <li>
                            <Link className='navbar-links-Link' to='/reservations' onClick={handleCloseBurgerIcon}>Reservations</Link>
                        </li>
                        <li>
                            <Link className='navbar-links-Link' to='/search' onClick={handleCloseBurgerIcon}>Search</Link>
                        </li>
                        <li>
                            <Link className='navbar-links-Link' to='/contact' onClick={handleCloseBurgerIcon}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;