import { useContext } from "react";
import { MyContext } from "../context";
import ContactModal from "./ContactModal";

const ContactFormModal = () => {
  const context = useContext(MyContext);
  const contactFormModalActive = context.state.contactFormModalActive;

  return (
    <>
      {contactFormModalActive ? (
        <div className="contact-form-modal">
          <div className="contact-form-modal-content">
            <ContactModal />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ContactFormModal;
