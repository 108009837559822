import Carousel from "nuka-carousel";

const CarouselComponent = (props) => {

    const carouselParams = {
        autoplay: true,
        autoplayInterval: 5000,
        pauseOnHover: true,
        swiping: true,
        wrapAround: true,
        speed: 1000,
        frameAriaLabel: '',
        animation: ''
    }

    const data = props.props.data.map((items) => {
        const carouselBackground = {
            backgroundImage: `url(${items.bannerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '50vh',
            width: '100%'
        }

        return <div className='carousel' style={{ ...carouselBackground }}>
            <div className='carousel-info' data-aos="fade-up" data-aos-duration="1000">
                <div className='carousel-info-details'>
                    <img src={items.logo.black} alt='Carousel' />
                    <p className='for-as-low-as'>For as low as</p>
                    <p className='monthly'>{items.cardPrice}</p>
                    <p className='description'>{items.cardDescription}</p>
                </div>
            </div>
        </div>
    });

    return (
        <>
            <Carousel {...carouselParams}
                renderCenterLeftControls={() => null}
                renderCenterRightControls={() => null}
            >
                {data}
            </Carousel>
        </>
    )
}

export default CarouselComponent;