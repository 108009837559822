import { useContext } from "react";
import { MyContext } from "../context";

const ContactIcons = () => {
  const context = useContext(MyContext);
  const handleContactFormModal = context.handleContactFormModal;

  return (
    <>
      <div className="contact-floating-icons">
        <i
          className="fa-solid fa-envelope email-icon"
          onClick={handleContactFormModal}
        ></i>
        <a href="https://www.facebook.com/smresidencesbygeraldinegarcia?mibextid=LQQJ4d">
          <i className="fa-brands fa-facebook-messenger messenger-icon"></i>
        </a>
        {/* <a href="https://web.whatsapp.com"> */}
        {/* <a href="https://wa.me/639665839308"> */}
        <a href="https://wa.me/639457230927">
          <i className="fa-brands fa-whatsapp whatsapp-icon"></i>
        </a>
        <a href="https://www.viber.com/">
          <i className="fa-brands fa-viber viber-icon"></i>
        </a>
      </div>
    </>
  );
};

// New Email: geraldinegarcia.smdc@gmail.com

export default ContactIcons;
