import PreSellingSingleItem from "./PreSellingSingleItem";
import ContactUs from "../ContactUs";

const ShowSingleItem = () => {

    return (
        <>
            <PreSellingSingleItem />
            <ContactUs />
        </>
    )
}

export default ShowSingleItem;