import PremierSingleItem from "./PremierSingleItem";
import ContactUs from "../ContactUs";

const PremierShowSingleItem = () => {

    return (
        <>
            <PremierSingleItem />
            <ContactUs />
        </>
    )
}

export default PremierShowSingleItem;