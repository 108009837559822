import CarouselComponent from "./Carousel";

const Banner = (props) => {

    return (
        <>
            <CarouselComponent props={props} />
        </>
    )
}

export default Banner;