import BestSellerSingleItem from "./BestSellerSingleItem";
import ContactUs from "../ContactUs";

const ShowBestSellerSingleItem = () => {

    return (
        <>
            <BestSellerSingleItem />
            <ContactUs />
        </>
    )
}

export default ShowBestSellerSingleItem;