import { Link } from "react-router-dom";
import SimpleCodesPH from './SimpleCodesPH.png';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-info'>
                <div>
                    <h1 className='font-lato'>About SMDC</h1>
                    <p className='font-lato font-weight-300 font-spacing text-justify text-line-height-12rem'>Every single day, SM touches the lives of millions of people through its stores, malls, banks, hotels and leisure facilities.
                        Now, Filipinos can live in style, comfort, and convenience with SM Development Corporation.</p>
                </div>
                <div>
                    <h1 className='font-lato'>Disclaimer</h1>
                    <p className='font-lato font-weight-300 font-spacing text-justify text-line-height-12rem'>To promote SMDC Projects and increase Sales and Marketing of SMDC Condominiums,
                        all information stated are intended to give a general overview of the project only and does not constitute any part of an offer or contract.
                        <a
                            href='www.smdc.com'
                            target='_blank'
                            rel='noreferrer'
                            className='text-decoration-none font-lato'
                        > <b>www.smdc.com</b></a> is the official website of SMDC.</p>
                </div>
                <div className='footer-links'>
                    <h1 className='font-lato'>Links</h1>
                    <Link className='navbar-links-Link' to='/'><p className='font-lato'>Home</p></Link>
                    <Link className='navbar-links-Link' to='/ContactUs'><p className='font-lato'>Contact</p></Link>
                    <Link className='navbar-links-Link' to='/Properties'><p className='font-lato'>Properties</p></Link>
                    <Link className='navbar-links-Link' to='HowToReserve'><p className='font-lato'>How to Reserve</p></Link>
                </div>
                <div>
                    <h1 className='font-lato'>Developer</h1>
                    <a href='https://www.facebook.com/profile.php?id=100090543049775' target='_blank' rel='noreferrer'>
                        <img className='SimpleCodesPH' src={SimpleCodesPH} alt='SimpleCodesPH' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;