import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MyContext } from "../context";

import NoRouteFound from "./NoRouteFound";

const SingleItemDynamic = () => {
  const params = useParams();
  const context = useContext(MyContext);
  const PropertiesJSON = context.state.PropertiesJSON;
  const showImages = context.state.showImages;
  const handleShowImagesButton = context.handleShowImagesButton;

  let keywords = params.name.toLowerCase().split("-")[0].split(" ")[0];
  let title = "";

  const handleUseEffect = () => {
    try {
      let filtered = PropertiesJSON.filter((item) => {
        return item.name.toLowerCase().indexOf(keywords) > -1;
      });
      return (title = filtered[0].name);
    } catch (error) {
      // console.log(error);
      console.log("invalid URL");
      return (title = "Invalid URL");
    }
  };

  handleUseEffect();
  useEffect(() => {
    const lowerCaseTitle = title.toLowerCase().split(" ").join("-");
    document.title = `SMDC ${title}`;
    let linkCanonical = document.querySelector("link[rel='canonical']");
    linkCanonical.setAttribute(
      "href",
      `https://www.sm-residences.com/properties/${lowerCaseTitle}`
    );
    console.log(title);
    console.log(linkCanonical);
  });

  try {
    let filtered = PropertiesJSON.filter((item) => {
      return item.name.toLowerCase().indexOf(keywords) > -1;
    });
    const singleItem = filtered[0];

    const singleItemBackground = {
      backgroundImage: `url(${singleItem.bannerImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "90vh",
      width: "100%",
    };

    const bannerFeatures = singleItem.bannerFeatures.map((features, index) => (
      <li className="font-lato" key={index}>
        {features}
      </li>
    ));

    const bannerAmenities = singleItem.bannerAmenities.map(
      (amenities, index) => (
        <li className="font-lato" key={index}>
          {amenities}
        </li>
      )
    );

    const gallery = singleItem.gallery.map((images, index) => (
      <img
        src={images}
        key={index}
        alt="Gallery"
        data-aos="fade-up"
        data-aos-duration="1000"
      />
    ));

    const showImage = showImages ? gallery : null;

    return (
      <>
        <div
          className="single-item-banner"
          style={{ ...singleItemBackground }}
          alt="Banner"
        >
          <div className="single-item-logo-container">
            <img src={singleItem.logo.white} alt={singleItem.name} />
          </div>
        </div>
        <div>
          <div className="single-item-banner-container">
            <div data-aos="fade-up" data-aos-duration="1000">
              <h1>About</h1>
              <p>{singleItem.bannerAbout}</p>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000">
              <h2>{singleItem.bannerFeaturesHeading}</h2>
              <ol>{bannerFeatures}</ol>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000">
              <h2>{singleItem.bannerAmenitiesHeading}</h2>
              <ol>{bannerAmenities}</ol>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-filler margin-top-5rem">
            <img
              src={singleItem.logo.black}
              alt="logoBlack"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
          <div className="button-view-more-properties">
            <button onClick={handleShowImagesButton}>Show Images</button>
          </div>
          <div className="gallery margin-top-5rem">{showImage}</div>
        </div>
      </>
    );
  } catch (error) {
    // console.log(error);
    console.log("invalid URL");
    return <NoRouteFound />;
  }
};

export default SingleItemDynamic;
